<template>
<div id="app" class="app app-full-height app-without-header">
    <div class="login">
        <div class="login-content">
            <form @submit.prevent="submitpasswordchange" name="login_form">
            <h1 class="text-center">Forgot Password</h1>
            <div class="text-muted text-center mb-4">
            You Forgot your Password
            </div>
            
            <div class="mb-3">
            <label class="form-label">Verify Code</label>
            <input type="number" v-model="forminput.passcode" class="form-control form-control-lg fs-15px" placeholder="Enter Verify Code" />
            <div v-if="formerror.passcode" class=" btn-sm btn-danger"  >{{ formerror.passcode}}</div>
            <div v-if="msg.passcode" class=" btn-sm btn-danger text-center" >{{JSON.stringify(msg.passcode).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            
            </div>
            <div class="mb-3">
            <label class="form-label">Enter New Password</label>
            <input type="password" v-model="forminput.password" class="form-control form-control-lg fs-15px" placeholder="Enter New Password" />
            <div v-if="formerror.password" class=" btn-sm btn-danger"  >{{ formerror.password}}</div>
            <div v-if="msg.password" class=" btn-sm btn-danger text-center" >{{JSON.stringify(msg.password).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            </div>

            <div v-if="formerror.phone" class=" btn-sm btn-danger"  >{{ formerror.phone}}</div>
            <div v-if="msg.phone" class=" btn-sm btn-danger text-center" >{{JSON.stringify(msg.phone).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            
            <button v-if="loading == 'getverifycode'" class="btn btn-outline-primary btn-block" style="width:100%" >
                <span  class="spinner-grow spinner-grow-sm" role="status"></span> Logging In
            </button>
            <button v-else @click.prevent="submitpasswordchange" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Request Reset</button>
            <div class="text-center text-muted">
            I remember my Password Now <router-link :to="{ name: 'login' }">Login Now</router-link>
            </div>
            </form>
        </div>
    </div>
</div>

<a href="#" data-click="scroll-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>

</template>

<script>
import {mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return{
            forminput:{
                phone:'',
                password:'',
                passcode:''
            },


            formerror:{
                phone:'',
                password:'',
                passcode:''
            },



        }
    },

    computed:{
        ...mapGetters(['msg','loading','collectionnum'])
    },

    methods:{
        ...mapActions(['updatepasswordbysms']),

        submitpasswordchange(){
            if(this.collectionnum == ''){
                this.formerror.phone = 'I appears you never used the right procedure to reach this Page';
                setTimeout(() => this.formerror.phone = '', 3000)
            }else if(this.forminput.password== ''){
                this.formerror.password = 'A password is Required';
                setTimeout(() => this.formerror.password = '', 3000)
            }else if(this.forminput.passcode== ''){
                this.formerror.passcode = 'Enter the Verify Code Sent to your Number';
                setTimeout(() => this.formerror.passcode = '', 3000)
            }else{
                let data ={
                    phone: this.collectionnum,
                    password:this.forminput.password,
                    verify_code:this.forminput.passcode
                }
                this.updatepasswordbysms(data).then(()=>{

                })
            }
        }
        
    },


    created(){

    }
}
</script>